<template>
  <div id="issue" class="issue">
    <div class="header pa-2 pb-5 px-4" :class="$vuetify.breakpoint.mobile ? 'pt-4' : 'pt-6'">
      <v-btn x-large color="primary" @click="pickImage" :block="$vuetify.breakpoint.mobile ? true : false">
        <img src="~@/assets/log-it-logo-opacity.png" alt="log-it" height="34px" />
        <h3 class="ml-4">Log-it</h3>
      </v-btn>
    </div>
    <div class="bg-main pt-4">
      <IssueList ref="issueList" :takeNum="25">
        <template v-slot:header>
          <h3 class="px-4">Log-it {{ $t("t_history") }}</h3>
        </template></IssueList
      >
    </div>
  </div>
</template>

<script>
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { IssueService } from "@/services";
import Helpers from "@/mixins/helpers";
import { mapActions } from "vuex";
import IssueList from "@/components/issues/IssueList.vue";

export default {
  name: "Issues",
  metaInfo: {
    title: "Log-it",
  },
  components: { IssueList },
  mixins: [Helpers],
  data: () => ({
    image: null,
    issue: null,
    issues: null,
    drawer: null,
  }),
  methods: {
    ...mapActions("issue", ["setImage"]),
    reset() {
      this.issue = null;
      this.image = null;
      this.getIssues();
    },
    async pickImage() {
      try {
        const image = await Camera.getPhoto({
          quality: 90,
          allowEditing: false,
          resultType: CameraResultType.Base64,
          source: this.$platform !== "web" ? CameraSource.Prompt : CameraSource.Photos,
        });
        this.image = image;
        if (image) {
          this.setImage(image);
          this.$router.push({ name: "issue" });
        }
      } catch (error) {
        console.error("Error picking images: ", error);
      }
    },

    async loadIssue(issueId) {
      const r = await IssueService.getIssue(issueId);
      this.issue = r.data;
      this.drawer = false;
    },

    async getIssues() {
      const r = await IssueService.getIssues();
      this.issues = r.data;
    },
  },
  mounted() {
    let params = this.$route.params;
    if (params.id) {
      this.loadIssue(params.id);
    }

    this.getIssues();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.params.evt) {
        vm.pickImage();
      }
      vm.prevRoute = from;
    });
  },
};
</script>
<style lang="scss">
.issue {
  .header {
    background: #eee;
    border-bottom: rgba(0, 0, 0, 0.1) 2px solid;
  }
  .theme--dark {
    .header {
      background: #272727;
      border-bottom: rgba(255, 255, 255, 0.4) 2px solid;
    }
  }
}
.theme--dark {
  .issue {
    .header {
      background: #272727;
      border-bottom: rgba(255, 255, 255, 0.4) 2px solid;
    }
  }
}
</style>
